import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoadingWall } from '@/components/layout/LoadingWall';
import { initFirebase } from './lib/firebase';

initFirebase()

const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        index: true,
        //@ts-ignore: test
        lazy: () => import('./pages/Home')
      },
      {
        path: 'about',
        //@ts-ignore: test
        lazy: () => import('./pages/About')
      },
      {
        path: 'services',
        //@ts-ignore: test
        lazy: () => import('./pages/Services')
      },
      {
        path: 'portfolio',
        //@ts-ignore: test
        lazy: () => import('./pages/Portfolio')
      },
      {
        path: 'contact',
        //@ts-ignore: test
        lazy: () => import('./pages/Contact')
      },
      {
        path: 'single-project',
        //@ts-ignore: test
        lazy: () => import('./pages/project-single')
      },
      // {
      //   path: ':projectName',
      //   //@ts-ignore: test
      //   lazy: () => import('./pages/project-single')
      // },
      {
        path: '/portfolio/:projectName',
        //@ts-ignore: test
        lazy: () => import('./pages/portfolio/ProjectDetail')
      },
      {
        path: 'profiles',
        //@ts-ignore: test
        lazy: () => import('./pages/Profiles')
      }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} fallbackElement={<LoadingWall />} />;
}

export default App;
