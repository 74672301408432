import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAzqFuJqxQkn8OORHoYayzRNkLooBwPdVA',
  authDomain: 'g-v-tech-website.firebaseapp.com',
  projectId: 'g-v-tech-website',
  storageBucket: 'g-v-tech-website.appspot.com',
  messagingSenderId: '163093838120',
  appId: '1:163093838120:web:a9b1186b799be2ed1b381d',
  measurementId: 'G-7950CY66BP'
};

export function initFirebase() {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
}
