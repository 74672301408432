export const LoadingWall = () => {
  return (
    <div id="pre-load">
      <div id="loader" className="loader">
        <div className="loader-container">
          <div className="loader-icon">
            <img src="/assets/images/fav.png" alt="Bizup Consulting Business" />
          </div>
        </div>
      </div>
    </div>
  );
};
